import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  NewLeadStatus,
  StatusList,
} from "../../Components/StatusList/StatusList";
import StatusColor from "../../Components/StatusColor/StatusColor";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Divider, Alert } from "@mui/material";
import Axios from "axios";
import { getStatus } from "./ApiCalls";
import CreateStatus from "../../Components/CreateStatus/CreateStatus";
// pre-catching 
import { useQuery } from "react-query";

const Status = () => {
  const [statusList, setStatusList] = useState([]);

  // useEffect(() => {
  //   getStatus()
  //     .then((response) => {
  //       console.log(response);
  //       setStatusList(response.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const [state, setState] = React.useState({
    bottom: false,
  });
  const [status, setStatus] = React.useState("");
  const [color, setColor] = React.useState("");

  // pre-catching
  const query = useQuery("allStatus", () => {
    return getStatus()
  },{})
  if(query.isLoading){
    return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
  }
  if(query.isError){
    return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleCallback = (childData) => {
    setColor(childData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(color);
  };

  const list = (anchor) => (
    <div className="create-status-container">
      <div className="create-status-header">
        <Button
          size="small"
          className="close-btn"
          onClick={toggleDrawer(anchor, false)}
        >
          <CloseIcon />
        </Button>
        <h3>Create Status</h3>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          sx={{ display: "flex" }}
          mt={2}
          px={2}
          py={3}
          style={{ background: "#fff" }}
        >
          <h3
            style={{
              margin: "0px 10px 0px 0px",
              fontSize: ".91rem",
              fontWeight: "700",
            }}
            sx={{ textTransform: "uppercase" }}
          >
            STATUS NAME
          </h3>
          <input
            type="text"
            name="status-name"
            placeholder="Enter Status Name..."
            className="status-name-input"
            onChange={(event) => setStatus(event.target.value)}
          />
        </Box>
        <Box mt={2} px={2} py={3} style={{ background: "#fff" }}>
          <h3
            style={{
              margin: "0px 10px 0px 0px",
              fontSize: ".91rem",
              fontWeight: "700",
            }}
            sx={{ textTransform: "uppercase" }}
          >
            STATUS COLOUR
          </h3>
          <div className="status-color-row">
            <StatusColor sendDataToParent={handleCallback} />
          </div>
        </Box>
        <Button
          type="submit"
          variant="contained"
          startIcon={<CheckIcon />}
          className="custom-submit-button"
        >
          CREATE STATUS
        </Button>
      </form>
    </div>
  );
  return (
    <>
      <Box>
        <NewLeadStatus />
      </Box>
      <Divider variant="inset" sx={{ ml: 0, border: "4.5px solid #f2f2f7" }} />
      <Box sx={{ mb: 8 }}>
        {query.data.data?.map((list) => (
          <StatusList
            key={list.status}
            lead_status={list.status}
            lead_status_count={list.status_count}
            lead_color={list.color}
          />
        ))}
      </Box>
      <CreateStatus
        creatbtn={
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: "fixed", right: "1rem", bottom: "4.5rem", boxShadow: 'none', filter: 'drop-shadow(0px 0px 3px rgb(0 0 0 / 1))' }}
          >
            <AddIcon />
          </Fab>
        }
      />
    </>
  );
};

export default Status;
import React, {useState, useEffect}  from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Leads.css';
import LeadCard from '../../Components/LeadCard/LeadCard';
import Header from '../../Components/Header/Header';
import Status from '../Status/Status';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, List, Alert, Backdrop, SpeedDial, SpeedDialIcon, SpeedDialAction, Skeleton, ListItem, ListItemAvatar} from '@mui/material'; 
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { getLeads } from './ApiCalls';
// pre-catching
import { useQuery, useInfiniteQuery } from "react-query";
import { queryClient } from '../../App';
import { API_URL } from '../../App';
import axios from 'axios'; 
import InfiniteScroll from "react-infinite-scroller"; 
import { Fade, Slide } from '@mui/material';

// Backdrop & + icon
const actions = [
  { icon: <PlaylistAddIcon />, name: 'Add' },
  // { icon: <SaveIcon />, name: 'Save' },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Leads() {
  
  const [value, setValue] = React.useState(0);
  const[leads, setLeads] = React.useState([]);
  const queryParams = new URLSearchParams(window.location.search);

  const LIMIT = 10;
  const {data, isLoading, isError, isSuccess, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage} = useInfiniteQuery(
    'repos', 
    ({pageParam = 0}) => getLeads(LIMIT,pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage < lastPage.totalPages) return lastPage.nextPage;
        return undefined;
      }
    }
  )

  // Backdrop & + icon
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  function addLead() {
    navigate("/addlead");
  }
 
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 0 && queryParams.has('statustab')){
      navigate("/leads");
    }else if(newValue === 0 && queryParams.has('statuscreated')){
      navigate("/leads");
    }
  };

  useEffect(() => { 
    if(queryParams.has('statustab')){
      setValue(1);
    }else if(queryParams.has('statuscreated')){
      setValue(0); 
      setTimeout(() => { 
        setValue(1);
        navigate('/leads');
      }, 600);
    } 
  }, [queryParams, navigate]);

  return (
    <>
      <Header/>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ backgroundColor: 'white', pt: '5.9rem' }}>
         <Fade in timeout={800}>
          <Tabs className='leadstabs' sx={{ position: 'fixed', zIndex: 1000, top: '3.4rem', pl: 2, width: '100%', minHeight: '40px', backgroundColor: 'white', borderBottom: 1, borderColor: 'divider'}} value={value} onChange={handleChange} aria-label="basic tabs example" >
            <Tab sx={{minHeight: '40px'}} label="All Leads" {...a11yProps(0)} />
            <Tab sx={{minHeight: '40px'}} label="Status" {...a11yProps(1)} />
          </Tabs>
         </Fade>
        </Box> 
        <TabPanel className='tabpanel' value={value} index={0}>
         <Fade in timeout={800}>
          <List sx={{ width: '100%', p: 0, mb: 8, bgcolor: 'background.paper' }}>
            {!isSuccess ? Array.from({ length: 15 }, (value, key) => 
              <ListItem key={key}>  
                <ListItemAvatar>
                  <Skeleton
                    variant="rectangular"
                    sx={{p:'7px 8px', width: '40px', height: '40px'}}
                    animation="wave"
                  />
                </ListItemAvatar>
                <Skeleton
                  variant="rectangular"
                  sx={{ml: 1.6, mt: -3.5, mb: 0, width: '100%', height: '35px'}}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  className="leadbutton"
                  sx={{width: '50px', height: '15px', mt: -3.5}}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  className="leaddate"
                  sx={{width: '50px', height: '15px'}}
                  animation="wave"
                />
              </ListItem>)
            : (data.pages[0].response.data !== 'No Data Found' ?
              <InfiniteScroll loader={<div className="loader" key={0}>Loading ...</div>} useWindow={true} threshold={50} hasMore={hasNextPage} loadMore={fetchNextPage}>
                <List sx={{ width: '100%', p: 0, mb: 8, bgcolor: 'background.paper' }}>
                  {data.pages.map((page) => (
                    page.response.data.map((lead)=>(
                    <LeadCard
                      key={lead.l_id}
                      lead_id = {lead.l_id}
                      lead_name = {lead.lname}
                      lead_pname = {lead.pname}
                      lead_source = {lead.source}
                      lead_color = {lead.color}
                      lead_status={lead.status}
                      lead_create_dt = {lead.create_dt}
                      lead_clicked = {lead.clicked}
                    />
                    ))
                  ))}
                </List>
              </InfiniteScroll> : <Alert severity="info">No new lead found!</Alert>
            )}
 
            {/* speed dial */}
            <Backdrop open={open} sx={{zIndex: '1000'}} />
            <SpeedDial
              ariaLabel="SpeedDial"
              sx={{ position: "fixed", right: "1rem", bottom: "4.5rem" }}
              icon={<SpeedDialIcon />}
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
              className='leadsflotbtn'
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  onClick={addLead}
                />
              ))}
            </SpeedDial>
            {/* ends */}
          </List>
         </Fade>
        </TabPanel>
        <TabPanel className='tabpanel' value={value} index={1}>
          <Status />
        </TabPanel> 
      </Box>
    </>
  );
}
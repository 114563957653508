import React from "react";
import { useState } from "react";
import { List, Divider, Alert, ListItem, ListItemAvatar, Skeleton } from "@mui/material";
import FollowLeadCard from "../../Components/FollowLeadCard/FollowLeadCard";
import { InnerPageHeader } from "../../Components/PageHeader/PageHeader";
import "./MissedLead.css"; 
import { getLeads, getMissedLeadCount } from "./ApiCalls";
import {useInfiniteQuery} from "react-query";
import InfiniteScroll from "react-infinite-scroller";
import dayjs from "dayjs";

const MissedLead = () => {

  const [missedLeads, setMissedLeads] = useState([]);
  const [missedLeadsCount, setMissedLeadsCount] = React.useState();

  React.useEffect(() => {
    getMissedLeadCount().then(response => {
      setMissedLeadsCount(response)
      console.log(response);
    }).catch(err => {
      console.log(err);
    });
  }, []);

  const LIMIT = 10;

  const {
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    ...result
  } = useInfiniteQuery({
    queryKey : 'all_missedleads',
    queryFn: ({ pageParam = 0 }) => getLeads(LIMIT, pageParam, 'New Lead'),
    getNextPageParam: (lastPage, allPages) =>  {
      if (lastPage.nextPage < missedLeadsCount) return lastPage.nextPage;
      return undefined; 
    }
  })

  return (
    <>
      <InnerPageHeader pagename="Missed Leads" backto="/followup" />
      <Divider variant="hr" sx={{ mt: 1 }} />
      <List sx={{ width: "100%", p: 0, mb: 0, bgcolor: "background.paper" }}>
        {!result.isSuccess ? Array.from({ length: 15 }, (value, key) => <ListItem key={key}>
          <ListItemAvatar>
            <Skeleton
              variant="rectangular"
              sx={{ p: '7px 8px', width: '40px', height: '40px' }}
              animation="wave"
            />
          </ListItemAvatar>
          <Skeleton
            variant="rectangular"
            sx={{ ml: 1.6, mt: -3.5, mb: 0, width: '100%', height: '35px' }}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            className="leadbutton"
            sx={{ width: '50px', height: '15px', mt: -3.5 }}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            className="leaddate"
            sx={{ width: '50px', height: '15px' }}
            animation="wave"
          />
        </ListItem>)
        : (result.data.pages[0].response.data !== 'No Data Found' ?
            (<InfiniteScroll loader={<div className="loader" key={0}>Loading ...</div>} useWindow={true} threshold={200} hasMore={hasNextPage} loadMore={fetchNextPage}>
              <List sx={{ width: '100%'}}>
                {
                  result.data.pages.map((page) => (
                    page.response.data.map((lead) => (
                      <FollowLeadCard  key={lead.l_id}
                        lead_id = {lead.l_id}
                        lead_name = {lead.lname}
                        lead_pname = {lead.pname}
                        lead_source = {lead.source}
                        lead_status={lead.status}
                        lead_color={lead.color}
                        lead_due={"Due " + dayjs(lead.followup_dt).format('DD MMM YYYY')}
                        lead_due_time={lead.followup_dt}
                      />
                    ))
                  ))
                }
              </List>
            </InfiniteScroll>) : <Alert severity="info">No new lead found!</Alert>
          )}
      </List>
      {/* <List sx={{ width: "100%", p: 0, mb: 0, bgcolor: "background.paper" }}>
         {
           missedLeads.length !== 0 ? (
            missedLeads?.map((lead)=>(
              <FollowLeadCard  key={lead.l_id}
              lead_id = {lead.l_id}
              lead_name = {lead.lname}
              lead_pname = {lead.pname}
              lead_source = {lead.source}
              lead_status={lead.status}
              lead_color={lead.color}
              lead_due={"Due " + dayjs(lead.followup_dt).format('DD MMM YYYY')}
              lead_due_time={lead.followup_dt}
              />
            )) 
            ) : (
              <Alert severity="info">No missed lead found!</Alert>
            )
          }
      </List> */}
    </>
  );
};

export default MissedLead;
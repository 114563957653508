import React from "react";
import { Divider, Button } from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./PageHeader.css";

export const PageHeader = (props) => {
  return (
    <>
      <div className="top-header">
        <h3>{props.pagename}</h3>
      </div>
      <Divider variant="inset" sx={{ ml: 0 }} />
    </>
  );
};

export const InnerPageHeader = (props) => {
  return (
    <>
      <div className="inner-top-header">
        <Button
          size="small"
          className="close-btn"
          component={Link}
          to={props.backto}
        >
          <KeyboardBackspaceIcon />
        </Button>
        <h3>{props.pagename}</h3>
      </div>
      <Divider variant="inset" sx={{ ml: 0 }} />
    </>
  );
};

import { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Login from './Pages/Login';
import Leads from './Pages/Leads/Leads';
import FollowUp from './Pages/FollowUp/FollowUp';
import Account from './Pages/Account/Account';
import Error from './Pages/Error';
import ViewLead from './Pages/ViewLead/Viewlead';
import Status from './Pages/Status/Status';
import AddLead from './Pages/AddLead/AddLead';
import EditLead from './Pages/EditLead/EditLead';
import MissedLead from './Pages/MissedLead/MissedLead';
import NewLead from './Pages/NewLead/NewLead';
import StatusLead from './Pages/StatusLead/StatusLead';
import UpcomingLead from './Pages/UpcomingLead/UpcomingLead';
import SomedayLead from './Pages/SomedayLead/SomedayLead';
import MultiSelect from './Pages/MultiSelect/MultiSelect'; 
import EditProfile from './Pages/EditProfile/EditProfile';
import SearchLead from './Pages/Search/Search';
// pre-catching
import {QueryClientProvider,  QueryClient} from "react-query";

// server api
// export const API_URL = "https://backendServerraunakdambir.co.in.rabstesting.in";
//  export const API_URL = "https://crudserver.rabstesting.in";
export const API_URL = "https://reactserver.rabsconnect.in";
// export const API_URL = "http://localhost:3004";
axios.defaults.withCredentials = true;

// routes
// const Login = lazy(() => import('./Pages/Login'));
// const Leads = lazy(() => import('./Pages/Leads/Leads'));
// const Error = lazy(() => import('./Pages/Error'));
// const ViewLead = lazy(() => import('./Pages/ViewLead/Viewlead'));
// const Status = lazy(() => import('./Pages/Status/Status'));
// const AddLead = lazy(() => import('./Pages/AddLead/AddLead'));
// const EditLead = lazy(() => import('./Pages/EditLead/EditLead'));
// const MissedLead = lazy(() => import('./Pages/MissedLead/MissedLead'));
// const NewLead = lazy(() => import('./Pages/NewLead/NewLead'));
// const StatusLead = lazy(() => import('./Pages/StatusLead/StatusLead'));
// const UpcomingLead = lazy(() => import('./Pages/UpcomingLead/UpcomingLead'));
// const SomedayLead = lazy(() => import('./Pages/SomedayLead/SomedayLead'));
// const FollowUp = lazy(() => import('./Pages/FollowUp/FollowUp'));
// const Account = lazy(() => import('./Pages/Account/Account'));
// const EditProfile = lazy(() => import('./Pages/EditProfile/EditProfile'));

// pre-catching
export const queryClient = new QueryClient();

function App() {

  let href = window.location.href;
  let count = href.split("//").length - 1;

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        {window.location.pathname === "/" || count > 1 ? window.location.pathname = "login" : null}
        {/* maxduration={0} */}
        <Suspense fallback={<Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>} >
          <Routes>
            <Route path="/" element={<Footer />}>
              <Route path="leads" element={<Leads />} />
              <Route path="followup" element={<FollowUp />} />
              <Route path="account" element={<Account />} />
              <Route path="error" element={<Error />} />
              <Route path="*" element={<Navigate replace to="login" />} />
              <Route path="status" element={<Status />} />
              <Route path="multiselect" element={<MultiSelect />} />
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="addlead" element={<AddLead />} />
            <Route path="editlead/:leadId" element={<EditLead />} />
            <Route path="viewlead/:leadId" element={<ViewLead />} />
            <Route path="missedlead" element={<MissedLead />} />
            <Route path="newlead" element={<NewLead />} />
            <Route path="statuslead/:leadStatus" element={<StatusLead />} />
            <Route path="upcominglead" element={<UpcomingLead />} />
            <Route path="somedaylead" element={<SomedayLead />} />
            <Route path="editprofile" element={<EditProfile />} /> 
            <Route path="search" element={<SearchLead />} />
          </Routes>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}
export default App;
import React, { useRef } from "react"; 
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Viewlead.css";
import BottomSlide from "../../Lib/BottomSlide";
import ReactWhatsapp from 'react-whatsapp';
import { styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Grid,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  // Drawer,
  // ListItemButton,
  // ListItemIcon,
  Typography,
  // FormControlLabel,
  // Radio,
  // RadioGroup,
  // FormControl,
  // TextField
  // FormLabel,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses
} from "@mui/lab"; 
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CallIcon from "@mui/icons-material/Call";
import SmsIcon from "@mui/icons-material/Sms";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
// import AddIcon from "@mui/icons-material/Add";
import DoorBackIcon from "@mui/icons-material/DoorBack";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import SaveIcon from "@mui/icons-material/Save";
// import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import dayjs from "dayjs"; 
import {
  getLeadData,
  // getStatus,
  setStatus,
  // setFollowupDate,
  setOtherDetails,
  setComment,
  unSetLead,
} from "./ApiCalls";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// Follow up lead modal
const Viewlead = () => {
  
  const [leadDetail, setLeadDetail] = React.useState(""); 
  const [currentStatusColor, setCurrentStatusColor] = React.useState();
  const [followupsdate, setFollowupsDate] = React.useState("No Followup");
  const [other_details, setOther_details] = React.useState();
  const [comment, setComments] = React.useState([]);
  // const [time, setTime] = React.useState(dayjs().format("YYYY-MM-DD HH:mm:ss"));
  const queryParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  
  let currentStatus = useRef(""); 

  let lead_id = useParams(); 

  React.useEffect(() => {
    getLeadData(lead_id).then((response) => {
      // for leads details
      setLeadDetail(response.data[0]);
      
      // for quick lead status
      response.data[0].status !== "" && response.data[0].status !== null ? 
        (currentStatus.current = response.data[0].status) && (setCurrentStatusColor(response.data[0].color)) 
      : (currentStatus.current = "No status") && (setCurrentStatusColor('#10aabe'));

      // for quick follow up
      if (response.data[0].followup_dt !== "0000-00-00 00:00:00"){
        setFollowupsDate(dayjs(response.data[0].followup_dt).format("MMM DD, YYYY h:mm A"))
      }

      // for quick other details
      setOther_details(response.data[0].other_details);
      
      // for quick comments  
      if (response.data[0].comments){
        setComments(response.data[0].comments.split("~"));
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }, [lead_id, followupsdate, other_details]);
  
  // for quick follow up
  const handleCallBack = (fdata) => {
    setFollowupsDate(fdata);
  }

  const updateStatus = () => async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("status") !== null && data.get("status").length !== 0) {
      const splitStatusColor = data.get("status").split('-')
      currentStatus.current = splitStatusColor[0];
      setCurrentStatusColor(splitStatusColor[1])
      setStatus(splitStatusColor[0], lead_id)
      .then((response) => { 

      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const updateOtherDetails = () => async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget); 
    const regex = /[a-zA-Z]/; // check alphabets 
    if (data.get("other_details") !== null && regex.test(data.get("other_details")) === true && data.get("other_details").length !== 0) {
      setOther_details(data.get("other_details"))
      setOtherDetails(data.get("other_details"), lead_id)
      .then((response) => {

      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const updateComment = () => async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget); 
    const regex = /[a-zA-Z]/; // check alphabets 
    if (data.get("comment") !== null && regex.test(data.get("comment")) === true && data.get("comment").length !== 0) {
      const usercomments = dayjs().format("MMM DD,YYYY h:mm A") + "- " + data.get("comment");
      setComments(arr => [...arr, usercomments]);
      comment.push(usercomments);
      let implode = "";
      if (comment !== null) {
        implode = comment.map((item) => item).join("~");
      } else {
        implode = comment;
      }
      setComment(implode, lead_id)
      .then((response) => {
          
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const deleteLead = () => async (event) => {
    event.preventDefault();
    unSetLead(lead_id)
    .then((response) => {
      if(queryParams.has('missedfollowup')){
        navigate("/missedlead");
      }else if(queryParams.has('upcomingfollowup')){
        navigate("/upcominglead");
      }else if(queryParams.has('todayfollowup')){
        navigate("/followup");
      }else{
        navigate("/leads");
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };
  
  // Read more
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = React.useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 84) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? " ... read more" : ". show less"}
        </span>
      </p>
    );
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={6}
            sx={{
              background: "#f2f2f7",
              position: "fixed",
              width: "100%",
              left: "0",
              zIndex: 1000,
            }}
          >
            <Button
              variant="text"
              component={Link} 
              to={queryParams.has('missedfollowup') ? "/missedlead" : 
                  queryParams.has('upcomingfollowup') ? "/upcominglead" :
                  queryParams.has('todayfollowup') ? "/followup" :
                  "/leads"
              }
              className="backbutton"
            >
              <KeyboardBackspaceIcon sx={{ color: "#333", fontSize: "26px", py: 0.5 }} />
            </Button>
          </Grid>
          <Grid item xs={6}
            sx={{
              background: "#f2f2f7",
              position: "fixed",
              width: "100%",
              right: "0",
              zIndex: 1000,
            }}
          >
            {/* view lead options */}
            <BottomSlide 
              sliderole="editoption" 
              slideid={lead_id.leadId}
              slidename="options" 
              slidebtnclass="options" 
              slidecloseclass="closeicon"
              slidelisttype={["Add", "Delete"]} // "Edit"
              slidedeletefun={deleteLead()}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 6 }}>
            <h1>{leadDetail.lname}</h1>
            {(leadDetail.locality && leadDetail.locality !== "null") ||
              (leadDetail.city && leadDetail.city !== "null") ? (
              <Box sx={{ display: "flex", marginLeft: "1.5rem" }}>
                <LocationOnOutlinedIcon sx={{ margin: "auto 5px auto 0px", fontSize: "1rem" }} />
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{ fontSize: "1rem" }}
                >
                  {leadDetail.locality ? leadDetail.locality + ", " : ""}
                  {leadDetail.city ? leadDetail.city : ""}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: "flex", marginLeft: "1.7rem" }}>
                -
              </Box>
            )}
            {leadDetail.source && leadDetail.source !== "null" ? (
              <Box sx={{ display: "flex", marginLeft: "1.5rem" }}>
                <DoorBackIcon sx={{ margin: "auto 5px auto 0px", fontSize: "1rem" }} />
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{ fontSize: "1rem" }}
                >
                  {leadDetail.source}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: "flex", marginLeft: "1.7rem" }}>
                -
              </Box>
            )}
          </Grid>
          <Grid item xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              borderRadius: 1,
              pt: 1,
              pb: 2,
              pl: 1.4,
              borderBottom: "1px solid #0000001f",
            }}
          > 
            <IconButton 
              aria-label="call" 
              onClick={leadDetail.p_mob === '' || leadDetail.p_mob === 0 ? 
                null : (e) => {
                  e.preventDefault();
                  window.location.href='tel:+'+leadDetail.p_ccode+leadDetail.p_mob;
                }
              }
            >
              <CallIcon className={leadDetail.p_mob === '' || leadDetail.p_mob === 0 ? 'licondisabled' : 'licon'} />
            </IconButton>  
            <IconButton 
              aria-label="sms"
              onClick={leadDetail.p_mob === '' || leadDetail.p_mob === 0 ?
                null : (e) => {
                  e.preventDefault();
                  window.location.href='sms://+'+leadDetail.p_ccode+leadDetail.p_mob+'?body=Hello,';
                }
              }
            >
              <SmsIcon className={leadDetail.p_mob === '' || leadDetail.p_mob === 0 ? 'licondisabled' : "licon"} />
            </IconButton>  
            <IconButton 
              aria-label="mail"
              onClick={leadDetail.p_email === '' || leadDetail.p_email === null ?
                null : (e) => {
                  e.preventDefault();
                  window.location.href='mailto:'+leadDetail.p_email;
                }
              }
            >
              <MailIcon className={leadDetail.p_email === '' || leadDetail.p_email === null ? 'licondisabled' : 'licon'} />
            </IconButton>   
            {leadDetail.p_mob === '' || leadDetail.p_mob === 0 ? 
              <IconButton aria-label="whatsapp">
                <WhatsAppIcon className='licondisabled' />
              </IconButton>   
              :
              <ReactWhatsapp 
                number={'+'+leadDetail.p_ccode+leadDetail.p_mob} 
                message="Hello," 
                style={{marginTop: '5px', backgroundColor: 'transparent', border: 'none'}}
              >
                <WhatsAppIcon className="licon" />
              </ReactWhatsapp>
            }
          </Grid>
          <Grid item xs={12}>
            <List
              sx={{
                width: "100%",
                height: "auto",
                p: 0,
                pb: 2,
                bgcolor: "background.paper",
              }}
            >
              {/* view lead assigned to */}
              <BottomSlide 
                sliderole="assignoption"
                slideid={lead_id.leadId}
                slidename="assigned to"
                slidebtnclass="assignedstatus"
                slidecloseclass="closeicon"
                slidelisttype={["No user found."]}
                // slidelisttype={["User 1", "User 2", "User 3", "User 4", "User 5"]}
              />
              
              {/* view lead follow up */}
              <BottomSlide
                sliderole="followup"
                slideid={lead_id.leadId}
                slidename="follow up"
                slidesubname={followupsdate}
                slidetxtclass="viewleadfollowup"
                slidecloseclass="viewleadcloseicon"
                slidecustomtimeclass="viewlead-time"
                slidecustomdateclass="viewlead-date"
                slidelisttype={["Today", "Tomorrow", "3 days from now", "1 week from now", "1 months from now", "Custom"]}
                slideupdatefollowup={handleCallBack}
              />
              <Divider variant="inset" component="li" sx={{ ml: 0 }} />
              
              {/* view lead status */}
              <BottomSlide
                sliderole="leadstatus"
                slideid={lead_id.leadId}
                slidename="Status" 
                slidecreatetitle="Create New"
                slidetxtclass="viewleadstatus"
                slidecloseclass="viewleadcloseicon" 
                slidecreateclass="createstatus"
                slidestatusbtnclass="lbutton"
                slidestatuscolorclass="status-color"
                slidestatuslableclass="statuslable"
                slidestatusbgcolor={currentStatusColor}
                slidecurrentstatus={currentStatus.current}
                slideupdatestatus={updateStatus()}
              />
              <Divider variant="inset" component="li" sx={{ ml: 0 }} />
              
              {/* view lead others details */}
              <BottomSlide
                sliderole="otherdetail"
                slideid={lead_id.leadId}
                slidename="other details"
                slidesubname={leadDetail.other_details !== '' && leadDetail.other_details !== null ? other_details : "add other details regarding project or client requirements"}
                slidetxtclass="viewleadotherdetail"
                slidecloseclass="closeicon"
                slidetextclass="addotherdetails"
                slideotherdetails={updateOtherDetails()}
              />
              <Divider variant="inset" component="li" sx={{ ml: 0 }} />
              
              {/* leads details */}
              <Box sx={{ display: "flex" }}>
                <ListItem>
                  <ListItemText
                    className="viewleadtitle"
                    sx={{ pl: 1 }}
                    primary="Category"
                    secondary={
                      leadDetail.pcategory !== " " && leadDetail.pcategory !== "null" ? leadDetail.pcategory : "-"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className="viewleadtitle"
                    sx={{ pl: 1 }}
                    primary="Apt. Type"
                    secondary={
                      leadDetail.ptype !== " " && leadDetail.ptype !== "null" ? leadDetail.ptype : "-"
                    }
                  />
                </ListItem>
              </Box>
              <Box sx={{ display: "flex" }}>
                <ListItem>
                  <ListItemText
                    className="viewleadtitle"
                    sx={{ pl: 1 }}
                    primary="Carpet Area"
                    secondary={
                      leadDetail.min_area + leadDetail.area_unit + " - " + leadDetail.max_area + leadDetail.area_unit
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className="viewleadtitle"
                    sx={{ pl: 1 }}
                    primary="Pricing"
                    secondary={
                      leadDetail.min_price ? leadDetail.min_price : "-" + leadDetail.max_price ? leadDetail.max_price : ""
                    }
                  />
                </ListItem>
              </Box>
              <Box sx={{ display: "flex" }}>
                <ListItem>
                  <ListItemText
                    className="viewleadtitle"
                    sx={{ pl: 1 }}
                    primary="Service Type"
                    secondary={
                      leadDetail.service_type !== "" && leadDetail.service_type !== "null" ? leadDetail.service_type : "-"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className="viewleadtitle"
                    sx={{ pl: 1 }}
                    primary="Configuration"
                    secondary={
                      leadDetail.pconfiguration !== "" && leadDetail.pconfiguration !== "null" ? leadDetail.pconfiguration : "-"
                    }
                  />
                </ListItem>
              </Box>
              <Divider variant="inset" component="li" sx={{ ml: 0 }} />
              {/* ends */}
              
              {/* TimeLine left aligned */}
              <ListItem>
                <ListItemText 
                  className="viewleadtitle" 
                  sx={{ pl: 1 }} 
                  primary="Timeline" 
                />
              </ListItem>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                <TimelineItem sx={{mt: '-1rem', ml: 1}}>
                  <TimelineSeparator>
                    {/* view lead timeline */}
                    <BottomSlide
                      sliderole="timelines"
                      slideid={lead_id.leadId}
                      slidename="add message"
                      slidecloseclass="closeicon"
                      slidemessageclass="addmessagebox"
                      slidetimelineplus="timelinedot"
                      slideaddtimeline={updateComment()}
                    />
                    <TimelineConnector /> 
                  </TimelineSeparator>
                  <TimelineContent sx={{ pl: 1 }}>
                    <BottomSlide
                      sliderole="timelines"
                      slideid={lead_id.leadId}
                      slidename="add comments"
                      slidecloseclass="closeicon"
                      slidemessageclass="addmessagebox"
                      slidetimelinetext="add activity"
                      slideaddtimeline={updateComment()}
                    />
                  </TimelineContent>
                </TimelineItem> 
                
                {/* dynamic comments */}
                {comment ?
                  comment.slice(0).reverse().map((c, i) => ( 
                    <TimelineItem key={i} sx={{ml: 1}}>
                      <TimelineSeparator>
                        <TimelineDot sx={{ background: "#c863ff" }}>
                          <SmsOutlinedIcon
                            color="white"
                            sx={{ width: "1em", fontSize: "1em" }}
                          />
                        </TimelineDot>
                        <TimelineConnector /> 
                      </TimelineSeparator> 
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            variant="h6"
                            component="span"
                            sx={{
                              fontSize: ".9rem",
                              fontWeight: "500",
                              marginRight: "0.5rem",
                              letterSpacing: "0.05em",
                            }}
                          >
                            {c.split("-")[0]}
                          </Typography> 
                        </Box>
                        <Typography
                          variant="h3"
                          component="span"
                          sx={{
                            fontSize: "0.95rem",
                            fontWeight: "500",
                          }}
                        >
                          Message
                        </Typography>
                        <Box sx={{ display: "flex" }} color="text.secondary">
                          <Typography
                            variant="h6"
                            component="span"
                            sx={{
                              fontSize: "0.8rem",
                              margin: "auto 0",
                            }} 
                          > 
                            {c.split("-")[1].length <= 84 ? 
                              c.split("-")[1] 
                            :
                              <ReadMore>
                                {c.split("-")[1]}
                              </ReadMore>
                            }
                          </Typography>
                        </Box>
                      </TimelineContent>
                  </TimelineItem>
                  ))
                : null}
              </Timeline>
              {/* ends */}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Viewlead;
import React, { useState, useEffect } from "react";
import { Divider, List, Alert } from "@mui/material";
import FollowupBar from "../../Components/FollowupBar/FollowupBar";
import { PageHeader } from "../../Components/PageHeader/PageHeader";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventIcon from "@mui/icons-material/Event";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FollowLeadCard from "../../Components/FollowLeadCard/FollowLeadCard";
import { getLeads, getMissedLeadCount, getUpcomingLeadCount } from "./ApiCalls";
// pre-fetching
import { useQuery } from "react-query";
import { queryClient } from '../../App';
import { API_URL } from '../../App';
import axios from "axios";

const FollowUp = () => {

  const today = useQuery("todayLeads", () => {
    return getLeads()
  },{})

  const upcomingLeads = useQuery("upcomingLeadsCount", () => {
    return getUpcomingLeadCount()
  },{})

  const missedLeads = useQuery("missedLeadsCount", () => {
    return getMissedLeadCount()
  },{})

  if(today.isLoading || upcomingLeads.isLoading || missedLeads.isLoading){
    return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
  }
  
  if(today.isError || upcomingLeads.isError || missedLeads.isError){
    return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
  }
 
  return (
    <>
      <PageHeader pagename="Follow Up" />
      <Divider variant="span" sx={{ mt: 1 }} />
      <FollowupBar
        followbar_color="rgb(214, 58, 58)"
        followbar_title="Missed Leads" 
        followbar_icon={<EventBusyIcon />}
        followbar_count={missedLeads.data.data[0].leadcount}
        redirect="/missedlead"
        followbar_righticon={<ChevronRightIcon />}
      />
      <Divider variant="span" sx={{ mb: 0 }} />
      <FollowupBar
        followbar_color="#000"
        followbar_title="Upcoming Leads" 
        followbar_icon={<EventIcon />}
        followbar_count={upcomingLeads.data.data[0].leadcount}
        redirect="/upcominglead"
        followbar_righticon={<ChevronRightIcon />}
      />
      <Divider variant="hr" sx={{ mb: 1 }} />
      <FollowupBar
        followbar_color="#1976d2"
        followbar_title={today.data.data !== 'No Data Found' ?  "Today ("+today.data.data.length+")" : 'Today (0)'} 
        followbar_icon={<EventAvailableIcon />}
        followbar_count=""
        followbar_righticon=""
      />
      <Divider variant="hr" sx={{ mb: 0 }} />
      <List sx={{ width: "100%", p: 0, mb: '4rem', bgcolor: "background.paper" }}>
        {today.data.data !== 'No Data Found' ? (
          today.data.data?.map((lead) => (
            <FollowLeadCard
              key={lead.l_id}
              lead_id={lead.l_id}
              lead_name={lead.lname}
              lead_pname={lead.pname}
              lead_source={lead.source}
              lead_status={lead.status}
              lead_color={lead.color}
              lead_due="Due Today"
              lead_due_time={lead.followup_dt}
            />
          ))
        ) : (
          <Alert severity="info">No todays lead found!</Alert>
        )}
      </List>
    </>
  );
};

export default FollowUp;
import React from "react";
import { ListItem } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./AccountBar.css";

const AccountBar = (props) => {
  return (
    <ListItem button className="account-bar" to="/leads">
      <div className="account-bar-name">
        <h3>{props.account_title}</h3>
      </div>
      <div className="right-side">
        <h3 className="account-bar-count">{props.account_subtitle}</h3>
        <div className="account-bar-righticon">
          <ChevronRightIcon />
        </div>
      </div>
    </ListItem>
  );
};

export default AccountBar;

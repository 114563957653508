import Axios from 'axios';
import { API_URL } from '../../App';

const setLead = async(data) =>{
    try{
        const response = await Axios.post(`${API_URL}/leads/editlead`, {
        // const response = await Axios.post('http://localhost:3004/leads/editlead', {
             u_id: '130',
             create_dt: '',
             update_dt: '',
             source_type: data.get('source_type'),
             brk_id: '',
             ref_ccode: data.get('ref_ccode'),
             ref_mob: data.get('ref_number'),
             ref_email: data.get('ref_email'),
             source: data.get('source'),
             service_type: data.get('service_type'),
             lname: data.get('lname'),
             p_ccode: data.get('lccode'),
             p_mob: data.get('lmobile'),
             p_email: data.get('lemail'),
             pname: data.get('pname'),
             ptype: data.get('ptype'),
             pcategory: data.get('pcategory'),
             pconfiguration: data.get('pconfiguration'),
             min_area: data.get('min_area'),
             max_area: data.get('max_area'),
             area_unit:'',
             min_price: data.get('min_price'),
             max_price: data.get('max_price'),
             country: data.get('country'),
             state: data.get('state'),
             city: data.get('city'),
             locality: data.get('locality'),
             sub_locality: data.get('sub_locality'),

          });
        return response;
    }catch(err){
        return err;
    }
}

const getLead = async(lead_id) => {
    try{
        const response = await Axios.post('/leads/leadDetail', {
            lead_id: lead_id,
          });
        return response;
    }catch(err){
        return err;
    }
}  

export { setLead, getLead }
import React, { useState, useEffect }  from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import '../Leads/Leads.css'; 
import LeadCard from '../../Components/LeadCard/LeadCard';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Tabs, Tab, Box, Typography, Button, List, Alert, Backdrop, SpeedDial, SpeedDialIcon, SpeedDialAction, Skeleton, ListItem, ListItemAvatar} from '@mui/material'; 
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"; 
import SearchIcon from '@mui/icons-material/Search'; 
import { getLeads } from '../Leads/ApiCalls';
import Checkbox from '@mui/material/Checkbox'; 
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
// pre-catching
import { useQuery, useInfiniteQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroller"; 
import { Fade, Slide } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit', '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const MultiSelect = () => {
  
  // infinite scroll
  const LIMIT = 10;
  const {data, isLoading, isError, isSuccess, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage} = useInfiniteQuery(
    'repos', 
    ({pageParam = 0}) => getLeads(LIMIT,pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage < lastPage.totalPages) return lastPage.nextPage;
        return undefined;
      }
    }
  )
  
  const [lids, setLids] = useState([]);
  const handleLid = (newId) => { 
    // setLids(arr => [...arr, newId]); 
    // setLids(newId); 
  }; 
  

  return (
    <>
      <Slide in direction='down' timeout={800}>
        <AppBar position="static" sx={{pt: '4px'}}>
          <Toolbar>
            <Grid container spacing={0} sx={{display: { md: 'none', lg: 'none', xl: 'none'}}}>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  component={Link} 
                  to={"/leads"}
                  className="backbutton"
                  sx={{p: 0, mr: 2}}
                >
                  <KeyboardBackspaceIcon sx={{ color: "#333", fontSize: "26px", py: 0.5 }}  />
                </Button>
                <Typography
                  variant="span"
                  noWrap
                  component="span"
                  sx={{color: '#333', fontWeight: '500'}}
                >
                  1&nbsp;selected
                </Typography> 
                <FormControlLabel 
                  control={<Checkbox size='small' sx={{p: 0, mr: 1}} />} 
                  label="Select All"
                  sx={{ml: 0, mr: 0, pt: 0.5,color: '#333', fontWeight: '400', float: 'right'}}
                /> 
              </Grid>
              <Grid item xs={12}>
                <Search sx={{mr:0, mt: 1, mb: 1}}>
                  <SearchIconWrapper sx={{px:1}} >
                    <SearchIcon sx={{ zIndex:'2', color:'#333'}} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    sx={{width: '100%', zIndex:'1'}}
                    placeholder="Search.."
                    inputProps={{ 'aria-label': 'search' }}
                  />
                  <Button sx={{ textTransform: 'capitalize', position: 'absolute', right: '0px', zIndex: 10000, top: '1px', fontSize: '12px', padding: '8px'}} >search</Button>
                </Search> 
              </Grid>
            </Grid> 
          </Toolbar>
        </AppBar>
      </Slide>
      <Box sx={{ width: '100%', pt: '6.3rem'}}>
        <Fade in timeout={1000}>
          <List sx={{ width: '100%', p: 0, mb: 8, bgcolor: 'background.paper' }}>
            {!isSuccess ? Array.from({ length: 15 }, (value, key) => 
              <ListItem key={key}>  
                <ListItemAvatar>
                  <Skeleton
                    variant="rectangular"
                    sx={{p:'7px 8px', width: '40px', height: '40px'}}
                    animation="wave"
                  />
                </ListItemAvatar>
                <Skeleton
                  variant="rectangular"
                  sx={{ml: 1.6, mt: -3.5, mb: 0, width: '100%', height: '35px'}}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  className="leadbutton"
                  sx={{width: '50px', height: '15px', mt: -3.5}}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  className="leaddate"
                  sx={{width: '50px', height: '15px'}}
                  animation="wave"
                />
              </ListItem>)
            : (
              <InfiniteScroll loader={<div className="loader" key={0}>Loading ...</div>} useWindow={true} threshold={50} hasMore={hasNextPage} loadMore={fetchNextPage}>
                <List sx={{ width: '100%', p: 0, mb: 8, bgcolor: 'background.paper' }}> 
                  {data.pages.map((page) => (
                    page.response.data.map((lead)=>(
                      <FormGroup key={lead.l_id} onClick={()=>handleLid(lead.l_id)}>
                        <FormControlLabel
                          sx={{width: '100%', display: 'block', ml: 0, mr: 0}} 
                          label={
                            <LeadCard
                              key={lead.l_id}
                              lead_id = {lead.l_id}
                              lead_name = {lead.lname}
                              lead_pname = {lead.pname}
                              lead_source = {lead.source}
                              lead_color = {lead.color}
                              lead_status={lead.status}
                              lead_create_dt = {lead.create_dt}
                              lead_clicked = {lead.clicked}
                            />    
                          }
                          control={
                            <Checkbox 
                              inputProps={{ 'aria-label': 'controlled' }}
                              size="small"
                              sx={{position: 'absolute', p: 0, mt: '6px', right: '0.6rem'}}
                              value={lead.l_id} 
                            />
                          } 
                          className="multichecklabel"
                        /> 
                      </FormGroup> 
                    ))
                  ))}
                </List>
              </InfiniteScroll>
            )} 
          </List>    
        </Fade>
      </Box> 
    </>
  )
}

export default MultiSelect;
import React from "react";
import { useState } from "react";
import { List, Divider, Alert, ListItem, ListItemAvatar } from "@mui/material";
import FollowLeadCard from "../../Components/FollowLeadCard/FollowLeadCard";
import { InnerPageHeader } from "../../Components/PageHeader/PageHeader";
import "./UpcomingLead.css";
import Skeleton from "@mui/material/Skeleton";
import { getLeads, getUpcomingLeadCount } from "./ApiCalls";
import {useInfiniteQuery} from "react-query";
import InfiniteScroll from "react-infinite-scroller";
import dayjs from "dayjs";

const UpcomingLead = () => {

  const[upcomingLeads, setUpcomingLeads] = useState([]);
  const [upcomingLeadsCount, setUpcomingLeadsCount] = React.useState();

  React.useEffect( () => {
    getUpcomingLeadCount().then(response => {
      setUpcomingLeadsCount(response.data)
      console.log(response);
    }).catch(err => {
      console.log(err);
    });
  }, []);

  const LIMIT = 10

  const { data, isLoading, isError, isSuccess, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery({
    queryKey :'all_upcomingleads',
    refetchOnWindowFocus: false,
    queryFn:({ pageParam = 0 }) => getLeads(LIMIT, pageParam),
    getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage < upcomingLeadsCount) return lastPage.nextPage;
        return undefined;
      }
  })

  return (
    <>
      <InnerPageHeader pagename="Upcoming Leads" backto="/followup" />
      <Divider variant="hr" sx={{ mt: 1 }} />
      {/* <List sx={{ width: "100%", p: 0, mb: 0, bgcolor: "background.paper" }}>
        {
          upcomingLeads.length !== 0 ? upcomingLeads.map((lead)=>(
            <FollowLeadCard  key={lead.l_id}
            lead_id = {lead.l_id}
            lead_name = {lead.lname}
            lead_pname = {lead.pname}
            lead_source = {lead.source}
            lead_status={lead.status}
            lead_color={lead.color}
            lead_due={"Due " + dayjs(lead.followup_dt).format('DD MMM YYYY')}
            lead_due_time={lead.followup_dt
            }
            />
          )) : <Alert severity="info">No upcoming lead found!</Alert>
        }
      </List> */}
      <List sx={{ width: "100%", p: 0, mb: 0, bgcolor: "background.paper" }}>
        {!isSuccess ? Array.from({ length: 15 }, (value, key) => 
          <ListItem key={key}>
            <ListItemAvatar>
              <Skeleton
                variant="rectangular"
                sx={{ p: '7px 8px', width: '40px', height: '40px' }}
                animation="wave"
              />
            </ListItemAvatar>
            <Skeleton
              variant="rectangular"
              sx={{ ml: 1.6, mt: -3.5, mb: 0, width: '100%', height: '35px' }}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              className="leadbutton"
              sx={{ width: '50px', height: '15px', mt: -3.5 }}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              className="leaddate"
              sx={{ width: '50px', height: '15px' }}
              animation="wave"
            />
          </ListItem>)
          : (data.pages[0].response.data !== 'No Data Found' ?
            (<InfiniteScroll loader={<div className="loader" key={0}>Loading ...</div>} useWindow={true} threshold={50} hasMore={hasNextPage} loadMore={fetchNextPage}>
              <List sx={{ width: '100%'}}>
                {
                  data.pages.map((page) => (
                    page.response.data.map((lead) => (
                      <FollowLeadCard  key={lead.l_id}
                      lead_id = {lead.l_id}
                      lead_name = {lead.lname}
                      lead_pname = {lead.pname}
                      lead_source = {lead.source}
                      lead_status={lead.status}
                      lead_color={lead.color}
                      lead_due={"Due " + dayjs(lead.followup_dt).format('DD MMM YYYY')}
                      lead_due_time={lead.followup_dt}
                      />
                    ))
                  ))
                }
              </List>
            </InfiniteScroll>) : <Alert severity="info">No new lead found!</Alert>
          )}
      </List>
    </>
  );
};

export default UpcomingLead;
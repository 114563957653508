import Axios from 'axios';
import { API_URL } from '../../App';

const getLeads = async(LIMIT, page) =>{
    try{
        const response = await Axios.post(`${API_URL}/leads/getAllLeads`,{limit: LIMIT, page: page});
        const responseCount = await Axios.post(`${API_URL}/leads/getAllLeadsCount`);
        return {response, nextPage: page + 10, totalPages: responseCount.data[0].leadcount };
    }catch(err){
        return err;
    }
}

const setClick = async(lid) =>{
    try{
        const response = await Axios.post(`${API_URL}/leads/setclick`, {lid: lid});
        return response;
    }catch(err){
        return err;
    }
}

export {getLeads, setClick}
import React from "react";
import { Button, Drawer, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import StatusColor from "../../Components/StatusColor/StatusColor";
import "./CreateStatus.css";
import { setNewStatus} from "./ApiCalls";
import { useNavigate } from "react-router-dom";
// toastify
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateStatus = (props) => {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const navigate = useNavigate();
  const[status, setStatus] = React.useState('');
  const[color, setColor] = React.useState('');

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleCallback = (childData) =>{
    setColor(childData)
  }

  const notify = () => toast.error('Status name is already taken.', {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Slide
  });

  const warn = () => toast.error('Status name or color should not be empty.', {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const handleSubmit = () => async() => {
    if(status && color){
      setNewStatus(status, color).then(response => { 
        if(response.data === 'success'){
          navigate(window.location.pathname+'?statuscreated');
          setState('bottom', false); 
          // let leadid = window.location.pathname.split('/');
          // navigate('/viewlead/'+leadid[leadid.length - 1]);
        }else{
          console.log('fail to create status');
          notify();
        }
      }).catch(err => {
        console.log(err);
      });
    }else{
      warn();
    }
  }
  const list = (anchor) => (
    <div className="create-status-container">
      <div className="create-status-header">
        <Button
          size="small"
          className="close-btn"
          onClick={toggleDrawer(anchor, false)}
        >
          <CloseIcon />
        </Button>
        <h3>Create Status</h3>
      </div>
      <form autoComplete="off" >
        <Box
          sx={{ display: "flex" }}
          mt={1}
          px={2}
          py={3}
          style={{ background: "#fff" }}
        >
          <h3
            style={{
              margin: "0px 10px 0px 0px",
              fontSize: ".91rem",
              fontWeight: "700",
            }}
            sx={{ textTransform: "uppercase" }}
          >
            STATUS NAME
          </h3>
          <input
            type="text"
            name="status-name"
            placeholder="Enter Status Name..."
            className="status-name-input"
            onChange={(event) => setStatus(event.target.value)}
          />
        </Box>
        <Box mt={1} px={2} py={3} style={{ background: "#fff" }}>
          <h3
            style={{
              margin: "0px 10px 0px 0px",
              fontSize: ".91rem",
              fontWeight: "700",
            }}
            sx={{ textTransform: "uppercase" }}
          >
            STATUS COLOUR
          </h3>
          <div className="status-color-row">
            <StatusColor sendDataToParent = {handleCallback}/>
          </div>
        </Box>
        <Button
          onClick={handleSubmit()}
          variant="contained"
          startIcon={<CheckIcon />}
          className="custom-submit-button"
          sx={{ position: "fixed" }}
        >
          CREATE STATUS
        </Button>
      </form>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss={false}
        // draggable
        // pauseOnHover
        theme="light"
        transition={Slide}
      />
    </div>
  );

  return (
    <>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div onClick={toggleDrawer(anchor, true)}>{props.creatbtn}</div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)} 
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
};

export default CreateStatus;
import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Link, Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import LoopIcon from '@mui/icons-material/Loop';
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Fade, Slide } from '@mui/material';

function refreshPage() {
  window.location.reload(false);
}

export default function Footer() {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    if(window.location.pathname === '/leads'){
      setValue('leads');
    }else if(window.location.pathname === '/followup'){
      setValue('followup');
    }else if(window.location.pathname === '/account'){
      setValue('account');
    }else if(window.location.pathname === '/multiselect'){
      setValue('multiselect');
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);

  return (
    <>
      <Slide in direction='up' timeout={800}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 , zIndex: '1000'}}
          elevation={5}
        >
          <Box sx={{ px: 1 }}>
          {window.location.pathname === '/leads' || 
           window.location.pathname === '/followup' || 
           window.location.pathname === '/account' ? 
            <BottomNavigation value={value} onChange={handleChange} className="footermenu">
              <BottomNavigationAction
                component={Link}
                to="/leads"
                label="Leads"
                value="leads"
                icon={
                  loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        bottom: 4,
                        borderRadius: 1,
                        width: "28px",
                        height: "20px",
                      }}
                      animation="wave"
                    />
                  ) : (
                    <GroupsRoundedIcon sx={{ fontSize: "28px" }} />
                  )
                }
                sx={{ lineHeight: 1 }}
              />
              <BottomNavigationAction
                component={Link}
                to="/followup"
                label="Follow Ups"
                value="followup"
                icon={
                  loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        bottom: 4,
                        borderRadius: 1,
                        width: "28px",
                        height: "20px",
                      }}
                      animation="wave"
                    />
                  ) : (
                    <EventAvailableOutlinedIcon sx={{ fontSize: "28px" }} />
                  )
                }
              />
              <BottomNavigationAction
                component={Link}
                to="/account"
                label="Account"
                value="account"
                icon={
                  loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        bottom: 4,
                        borderRadius: 1,
                        width: "28px",
                        height: "20px",
                      }}
                      animation="wave"
                    />
                  ) : (
                    <ManageAccountsRoundedIcon sx={{ fontSize: "28px" }} />
                  )
                }
              />
              <BottomNavigationAction
                label="Refresh"
                value="refresh"
                icon={<LoopIcon sx={{fontSize: '28px'}} />}
                onClick={refreshPage}
              />
            </BottomNavigation>
          :
            window.location.pathname === '/multiselect' ?
              <BottomNavigation showLabels value={value} onChange={handleChange} className="footermenu">
                <BottomNavigationAction
                  label="Assign"
                  value="assign"
                  icon={<GroupRoundedIcon sx={{fontSize: '28px'}} />} 
                />
                <BottomNavigationAction
                  label="Followup"
                  value="follow"
                  icon={<InsertInvitationRoundedIcon sx={{fontSize: '28px'}} />} 
                />  
                <BottomNavigationAction
                  label="Status"
                  value="status"
                  icon={<CategoryRoundedIcon sx={{fontSize: '28px'}} />} 
                />  
                {/* <BottomNavigationAction
                  label="More"
                  value="more"
                  icon={<MoreVertRoundedIcon sx={{fontSize: '28px'}} />} 
                />     */}
              </BottomNavigation>   
          : null}
          </Box>
        </Paper>
      </Slide>
      <Outlet />
    </>
  );
}

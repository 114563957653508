import * as React from 'react';
import { useNavigate } from "react-router-dom"; 
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';
import List from '@mui/material/List'; 
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle"; 
import TextField from "@mui/material/TextField"; 
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SaveIcon from "@mui/icons-material/Save";
// import CheckIcon from '@mui/icons-material/Check';
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"; 
import CreateStatus from "../Components/CreateStatus/CreateStatus"; 
import {
  // getLeadData,
  getStatus,
  // setStatus,
  setFollowupDate,
  // setOtherDetails,
  // setComment,
  // unSetLead,
} from "../Pages/ViewLead/ApiCalls";
import {
  // Timeline,
  // TimelineItem,
  // TimelineSeparator,
  // TimelineConnector,
  // TimelineContent,
  TimelineDot,
  // timelineItemClasses
} from "@mui/lab";
import { textAlign } from '@mui/system';

// Comman slide menu from bottom
const BottomSlide = (props) => {

  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  // slide role
  const sliderole = ['editoption', 'assignoption', 'followup', 'leadstatus', 'otherdetail', 'timelines'];
  
  // confirm box
  const [confirmbox, setConfirmBox] = React.useState(false);
  const openConfirmBox = () => {
    setConfirmBox(true);
  };
  const closeConfirmBox = () => {
    setConfirmBox(false);
  };

  // follow up 
  const [time, setTime] = React.useState(dayjs().format("YYYY-MM-DD HH:mm:ss"));
  const [mdate, setMdate] = React.useState(dayjs());
  const mdateChange = (newDate) => {
    setMdate(newDate); 
  };
  const updateFollowUp = (text) => async (event) => {
    event.preventDefault();
    let followup_date = "";
    if (text === "Today") {
      followup_date = dayjs().format("YYYY-MM-DD HH:mm:ss");
    } else if (text === "Tomorrow") {
      followup_date = dayjs().add(1, "day").format("YYYY-MM-DD HH:mm:ss");
    } else if (text === "3 days from now") {
      followup_date = dayjs().add(3, "day").format("YYYY-MM-DD HH:mm:ss");
    } else if (text === "1 week from now") {
      followup_date = dayjs().add(1, "week").format("YYYY-MM-DD HH:mm:ss");
    } else if (text === "1 months from now") {
      followup_date = dayjs().add(1, "month").format("YYYY-MM-DD HH:mm:ss");
    } else {
      followup_date = dayjs(mdate).format("YYYY-MM-DD");
    }
    
    const followup_time = dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    const ftime = followup_time.split(" ");
    const fdate = followup_date.split(" ");
    const final_date = fdate[0] + " " + ftime[1]
    setFollowupDate(final_date, props.slideid)
    .then((response) => {
      
    })
    .catch((err) => {
      console.log(err);
    });
    // for quick update
    props.slideupdatefollowup(fdate[0] + " " + ftime[1]);
  }; 

  // status
  const [allStatus, setAllStatus] = React.useState([]);
  const getAllStatus = () => {
    getStatus().then((response) => {
      // console.log(response);
      setAllStatus(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };
  const [status, setStatus] = React.useState(props.slidecurrentstatus+'-'+props.slidestatusbgcolor);
  const handleStatus = (event) => {
    setStatus(event.target.value);
  };
  
  // bottom slide logic
  const [state, setState] = React.useState({
    bottom: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    // get all status
    <React.Fragment>
      {props.sliderole === sliderole[3] ? getAllStatus() : null}
    </React.Fragment>
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  
  React.useEffect(() => { 
    if(queryParams.has('statuscreated')){  
      setState('bottom', false);
      navigate(window.location.pathname); 
    } 
  }, [queryParams, navigate]);
  
  const slidelist = (anchor) => (
    <Box 
      component="form" 
      onSubmit={
        props.sliderole === sliderole[3] ? props.slideupdatestatus :
        props.sliderole === sliderole[4] ? props.slideotherdetails : 
        props.sliderole === sliderole[5] ? props.slideaddtimeline : null
      }
    >
      <CloseOutlinedIcon
        onClick={toggleDrawer(anchor, false)}
        className={props.slidecloseclass}
        sx={{zIndex: props.sliderole === sliderole[1] ? '100!important' : null}}
      />
      <List 
        sx={{ 
          p: 0,
          height: props.sliderole === sliderole[1] || props.sliderole === sliderole[3] ? '350px' : null, 
          overflowY: props.sliderole === sliderole[1] || props.sliderole === sliderole[3] ? 'scroll' : null,
          zIndex: props.sliderole === sliderole[3] ? 1000 : null,
          mb: props.sliderole === sliderole[3] || props.sliderole === sliderole[4] || props.sliderole === sliderole[5] ? 3.5 : null,
        }}
      >
        <ListItem 
          sx={{ 
            background: "#e5e7eb", 
            p: 0, 
            width: props.sliderole === sliderole[1] || props.sliderole === sliderole[3] ? '100%' : null,
            position: props.sliderole === sliderole[1] || props.sliderole === sliderole[3] ? 'fixed' : null,
            zIndex: props.sliderole === sliderole[1] || props.sliderole === sliderole[3] ? '99!important' : null
          }}
        >
          <ListItemButton>
            <ListItemText
              sx={{ color: "#333", fontWeight: "500", textTransform: 'capitalize' }}
              primary={
                props.sliderole === sliderole[2] ? "Schedule follow up for" :
                props.sliderole === sliderole[3] ? "Manage Status" : 
                props.slidename
              }
            />
          </ListItemButton>
           
          { // for follow up
          props.sliderole === sliderole[2] ?
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                // label="For mobile"
                value={time}
                onChange={(newValue) => {
                  setTime(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                className={props.slidecustomtimeclass}
              />
            </LocalizationProvider>
          : // for lead status
          props.sliderole === sliderole[3] ?
            <CreateStatus creatbtn={<span className={props.slidecreateclass}>{props.slidecreatetitle}</span>} />      
          : null}
          {/* ends */}
        </ListItem>
        
        {/* space from top */}
        {props.sliderole === sliderole[1] ? <hr style={{marginBottom: '2.4rem'}} /> : null}
        
        {/* editoption role */}
        {props.sliderole === sliderole[0] ?
          props.slidelisttype.map((text, index) => (
            <ListItem
              key={index}
              sx={{ borderBottom: "1px solid #e5e7eb", padding: "0" }}
              // onClick={toggleDrawer(anchor, false)}
            >
              <ListItemButton
                sx={{ padding: "15px 20px" }} 
                to={ text === "Add" ? '/addlead' : 
                     text === 'Edit' ? `/editlead/${props.slideid}` : null }
                onClick={text === 'Delete' ? openConfirmBox : null}
              >
                <ListItemIcon sx={{ minWidth: "35px", color: "#10aabe" }}>
                  { text === "Add" ? <AddIcon />
                  : text === 'Edit' ? <EditRoundedIcon /> 
                  : text === 'Delete' ? <DeleteOutlineOutlinedIcon /> 
                  : null }
                </ListItemIcon>
                <ListItemText
                  primary={text+" Lead"}
                  sx={{ color: "#10aabe", fontWeight: "400!important" }}
                />
              </ListItemButton>     
            </ListItem>
          ))
        : // assigned to role
        props.sliderole === sliderole[1] ?
          props.slidelisttype.map((text, index) => (
            <ListItem
              key={index}
              sx={{ borderBottom: "1px solid #e5e7eb", padding: "0" }}
              // onClick={toggleDrawer(anchor, false)}
            >
              <ListItemButton
                sx={{ padding: "15px 20px" }}
              >
                <ListItemIcon sx={{ minWidth: "35px", color: "#10aabe" }}>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  sx={{ color: "#333", fontWeight: "400!important" }}
                />
              </ListItemButton>     
            </ListItem>
          ))
        : // schedule follow up for
        props.sliderole === sliderole[2] ?
          props.slidelisttype.map((text, index) => (
            <ListItem
              key={index}
              sx={{ borderBottom: "1px solid #e5e7eb", padding: "0" }}  
              onClick={updateFollowUp(text)}
            >
              {text === 'Custom' ?
                <ListItemButton sx={{ padding: "15px 20px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        className={props.slidecustomdateclass}
                        label="Custom"
                        inputFormat="MM/DD/YYYY"
                        value={mdate}
                        onChange={mdateChange}
                        onAccept={toggleDrawer(anchor, false)}
                        renderInput={(params) => <TextField {...params} />}
                        disablePast
                      />
                      <ListItemText
                        primary={
                          <CalendarMonthOutlinedIcon sx={{ fontSize: "22px" }} />
                        }
                        sx={{ textAlign: "right", m: 0, p: 0 }}
                        onClick={toggleDrawer(anchor, false)}
                      />
                  </LocalizationProvider>
                </ListItemButton>
              :
                <ListItemButton
                  sx={{ padding: "15px 20px" }}
                >
                  <ListItemText
                    primary={text}
                    sx={{ color: "#333", fontWeight: "400!important" }}
                    onClick={toggleDrawer(anchor, false)}
                  />
                  {/* <ListItemIcon sx={{ minWidth: "35px", color: "#10aabe", justifyContent: 'right' }}>
                    {
                      text === 'Today' || text === 'Tomorrow' || text === '3 days from now' || text === '1 week from now' || text === '1 months from now' ? <CheckIcon /> : null 
                    }
                  </ListItemIcon> */}
                </ListItemButton>
              }   
            </ListItem>
          ))
        : // lead status
        props.sliderole === sliderole[3] ?
          <FormControl sx={{ width: "100%", mt: 6}}>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              // defaultValue={props.slidecurrentstatus}
              name="radio-buttons-group"
              value={status}
              onChange={handleStatus}
            >
              {allStatus.length !== 0 ? 
                allStatus.map((text, index) => (
                  <ListItem
                    key={ index }
                    sx={{ borderBottom: "1px solid #e5e7eb", padding: "0" }}
                  >
                    <ListItemButton>
                      <ListItemIcon sx={{ minWidth: "35px" }}>
                        <div className={props.slidestatuscolorclass} style={{ backgroundColor: text.color }}
                        ></div>
                      </ListItemIcon>
                      <FormControlLabel
                        className={props.slidestatuslableclass}
                        labelPlacement="start"
                        control={<Radio name="status" value={text.status + "-" + text.color} />}
                        label={text.status}
                        sx={{width: "100%", ml: "0%!important", textTransform: 'capitalize'}}
                      />
                    </ListItemButton>
                  </ListItem>
                ))
              : 
                <ListItem>
                  <ListItemButton sx={{py: 5, justifyContent: 'center'}}> 
                    <span className={props.slidestatuslableclass} >
                      No status found
                    </span> 
                  </ListItemButton>
                </ListItem>
              }
            </RadioGroup>
          </FormControl>     
        : // other details
        props.sliderole === sliderole[4] ?
          <TextField
            className={props.slidetextclass}
            id="standard-multiline-static"
            name="other_details"
            multiline
            rows={4.8}
            // defaultValue=""
            variant="standard"
            placeholder="(at least few characters are required*)"
            sx={{ width: "100%", mb: 0.5}}
          />
        : // timelines
        props.sliderole === sliderole[5] ?
          <TextField
            className={props.slidemessageclass}
            id="standard-multiline-static"
            name="comment"
            multiline
            rows={4.8}
            // defaultValue=""
            variant="standard"
            placeholder="(at least few characters are required*)"
            sx={{ width: "100%", mb: 0.5}}
          />
        :
          <ListItem 
            sx={{ borderBottom: "1px solid #e5e7eb", padding: "0" }}
            onClick={toggleDrawer(anchor, false)}
          >
            <ListItemButton sx={{ padding: "15px 20px" }}>
              <ListItemIcon sx={{ minWidth: "35px", color: "#10aabe" }}>
                <FmdBadOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'No data found check slide role.'} sx={{ color: "#10aabe", fontWeight: "400!important" }} />
            </ListItemButton>
          </ListItem>
        }
        {/* ends */}

        {/* confirm box */}
        <Dialog
          open={confirmbox}
          onClose={closeConfirmBox}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to remove this lead ?"}
          </DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Lead will be deleted from your record.
            </DialogContentText>
          </DialogContent> */}
          <DialogActions>
            <Button onClick={closeConfirmBox}>No</Button>
            <Button onClick={props.slidedeletefun} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* ends */}
      </List>
      {props.sliderole === sliderole[3] || props.sliderole === sliderole[4] || props.sliderole === sliderole[5] ?
        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          className="custom-submit-button"
          onClick={toggleDrawer(anchor, false)}
        >
          {props.sliderole === sliderole[5] ? "Add" : "Save"}
        </Button>
       : null}
    </Box>
  );
  
  return (
    <>
      {props.sliderole === sliderole[0] || 
       props.sliderole === sliderole[1] ||
       props.sliderole === sliderole[2] ||
       props.sliderole === sliderole[3] ||
       props.sliderole === sliderole[4] ||
       props.sliderole === sliderole[5] ?
        ["bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            {props.sliderole === sliderole[2] || props.sliderole === sliderole[3] || props.sliderole === sliderole[4] ? 
              <ListItem
                button
                onClick={toggleDrawer(anchor, true)}
              >
                {props.sliderole === sliderole[3] ? 
                  <React.Fragment>
                    <ListItemText 
                      className={props.slidetxtclass}
                      sx={{ pl: 1, pb: 2.8}} 
                      primary={props.slidename} 
                    />
                    <Button className={props.slidestatusbtnclass} size="small" 
                      style={{ backgroundColor: props.slidestatusbgcolor }}
                    >
                      {props.slidecurrentstatus}
                    </Button>
                  </React.Fragment>
                :
                  <ListItemText 
                    className={props.slidetxtclass}
                    sx={{ pl: 1}} 
                    primary={props.slidename}
                    secondary={props.slidesubname}
                  />
                }
              </ListItem>
            :
              props.sliderole === sliderole[5] ?
                props.slidetimelineplus ? 
                  <TimelineDot 
                    color="primary" 
                    variant="outlined"
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <AddIcon
                      color="primary"
                      sx={{ width: "1em", fontSize: "1em" }}
                    />
                  </TimelineDot>
                :
                  <ListItem
                    button
                    variant="h6"
                    component="span"
                    color="primary"
                    sx={{
                      fontSize: "14px",
                      pl: 1, 
                      fontWeight: 500,
                      color: "#333",
                      textTransform: 'capitalize'
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    {props.slidetimelinetext}
                </ListItem>
            :
              <Button
                className={props.slidebtnclass}
                onClick={toggleDrawer(anchor, true)}
              >
                {props.sliderole === sliderole[1] ? <HowToRegIcon sx={{pr: 1}} /> : null}
                {props.slidename}
                {props.sliderole === sliderole[1] ? <KeyboardArrowDownIcon sx={{position: 'absolute', right: '0.6rem', zIndex: 100}} /> : null}
              </Button>
            }
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {slidelist(anchor)}
            </Drawer>
          </React.Fragment>
        ))
        : null
      }
    </>
  )
}

export default BottomSlide;
import React from "react";
import { ListItem, Divider } from "@mui/material";
import "./FollowupBar.css";
import { Link } from "react-router-dom";
const FollowupBar = (props) => {
  return (
    <ListItem
      button
      className="followup-bar"
      component={Link}
      to={props.redirect} 
    >
      <div className="followup-bar-icon" style={{color: props.followbar_color}}>{props.followbar_icon}</div>
      <div className="followup-bar-name">
        <h3 style={{color: props.followbar_color}}>{props.followbar_title}</h3>
      </div>
      <div className="right-side">
        <h3 className="followup-bar-count" style={{color: props.followbar_color}}>{props.followbar_count}</h3>
        <div className="followup-bar-righticon" style={{color: props.followbar_color}}>{props.followbar_righticon}</div>
      </div>
    </ListItem>
  );
};

export default FollowupBar;
